import React from "react"
import { graphql } from "gatsby"

import { HomeIcon } from '@heroicons/react/20/solid'
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Rightcol from "../components/rightcol"
import Rating from "../components/rating"

const CategoryPostTemplate = ({ data: { previous, next, category } }) => {
  const post = category.posts.nodes

  const chapter = {
    title: category.posts?.nodes[0].title || ``,
    uri: category.posts?.nodes[0].uri || ``,
    date: category.posts?.nodes[0].date || ``,
    tags: category.posts?.nodes[0].date || ``,
  }

  const el = Array.from(post)[0];
  const featuredImage = el?.featuredImage?.node?.guid
  const tags = el.tags.nodes

  const chapters = post.slice(0, 3).map(cat => {
      return (
        <tr key={cat.title}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
            <a href={cat.uri}>{cat.title}</a>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{cat.date}</td>
          <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">0</td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-xs font-medium sm:pr-6 ">
            <a href={cat.uri} className="text-indigo-600 hover:text-indigo-900">
              Read
            </a>
          </td>
        </tr>
      )
  }); 

  const pages = [
    { name: category.name, href: '#', current: false },
  ]
  return (
    <Layout>
      <Seo title={chapter.title} description={chapter.title} />
      <div className="flex-grow lg:flex border-t-4 border-teal-400 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
         
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <nav className="flex border-b border-gray-200 bg-white" aria-label="Breadcrumb">
              <ol className="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
                <li className="flex">
                  <div className="flex items-center">
                    <a href="/" className="text-gray-400 hover:text-gray-500">
                      <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                      <span className="sr-only">Home</span>
                    </a>
                  </div>
                </li>
                {pages.map((page) => (
                  <li key={page.name} className="flex">
                    <div className="flex items-center">
                      <svg
                        className="h-full w-6 flex-shrink-0 text-gray-200"
                        viewBox="0 0 24 44"
                        preserveAspectRatio="none"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                      </svg>
                      <a
                        href={page.href}
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>

            <div className="pl-2 pr-2 pt-4 pb-4   xl:border-t-0  xl:pt-6" isHomePage>
              <div className="items-center">

                  <div className="sm:flex sm:px-6 mb-6">
                    <div className="w-full sm:w-48 mr-4 flex-shrink-0">
                      {featuredImage
                        ? <img
                            src={featuredImage}
                            className="h-auto w-48 border border-gray-300 bg-white text-gray-300"
                            alt={category.name}
                          />
                        : 
                        <svg
                          className="h-auto w-48 border border-gray-300 bg-white text-gray-300"
                          preserveAspectRatio="none"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 200 200"
                          aria-hidden="true"
                        >
                          <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                        </svg>
                      }
                    </div>
                    <div className="w-full sm:w-9/12 pt-4 sm:pt-1">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{category.name}</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">{chapter.date}</p>
                      </div>
                      <div className="mt-5 border-t border-gray-200">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-2 sm:px-4 sm:grid sm:grid-cols-3">
                            <dt className="text-sm font-medium text-gray-500">Rating</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"><Rating/></dd>
                          </div>
                          <div className="py-2 sm:px-4 sm:grid sm:grid-cols-3">
                            <dt className="text-sm font-medium text-gray-500">Genre(s)</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {tags.map((tag) => (
                                  <a href={tag.link}>
                                    <span className="inline-flex items-center rounded bg-indigo-100 px-2 py-0.5 m-0.5 text-xs font-medium text-indigo-800">
                                      <svg className="mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx={4} cy={4} r={3} />
                                      </svg>
                                      {tag.name}
                                    </span>
                                  </a>
                              ))} 
                            </dd>
                          </div>
                          <div className="py-2 sm:px-4 sm:grid sm:grid-cols-3">
                            <dt className="text-sm font-medium text-gray-500">Summary</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {category.description}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <div className="px-4 sm:pt-6 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900 pt-4 sm:pt-1">All Chapters</h1>
                        <p className="mt-2 text-sm text-gray-700">
                          A list of all the latest manga releases.
                        </p>
                      </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Chapters
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Date
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Views
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Read
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                  {chapters}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default CategoryPostTemplate

export const pageQuery = graphql`
  query CategoryPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    category: wpCategory(id: { eq: $id }) {
      id
      name
      link
      uri
      description
      posts {
        nodes {
          title
          date(formatString: "MMMM DD, YYYY")
          uri
          featuredImage {
            node {
              guid
            }
          }
          tags {
            nodes {
              name
              link
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
